import React, { useEffect, useState } from "react";
import {
  DialogActions,
  DialogContentText,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  Tabs,
  Tab,
  TextField,
} from "@mui/material";
import { AddArticleImg, sendIcon } from "../../assets";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from "../../slices/notifier";
import constants from "../../utilities/constants";
import Select from "./../Select";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormProvider from "./../../hooks/hook-form/FormProvider";
import {
  createArticles,
  emptyArticleContent,
  getAllCategories,
  updateArticle,
} from "../../slices/helpdesk";
import Input from "../Input";
import { openModal } from "../../slices/modals";
import { useHistory, useLocation } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
import { clearHTMLTags } from "../../utilities/clearHtmlTags";

const generateValidationSchema = (articles) => {
  return Yup.object().shape({
    title: Yup.string()
      .strict(false)
      .trim()
      .min(2, "Must be longer than 2 characters")
      .required("Title is required"),
    articleCategory: Yup.string().notRequired("Category is not required"),
    articleStatus: Yup.string().required("Status is required"),
    articles: Yup.array().of(
      Yup.object().shape({
        title: Yup.string()
          .strict(false)
          .trim()
          .min(2, "Must be longer than 2 characters")
          .required("Title is required"),
      })
    ),
  });
};

const AddArticle = ({ id, open, handleClose, data }) => {
  const dispatch = useDispatch();
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));
  const { t } = useTranslation();
  const showSnackbar = (data) => {
    enqueueSnackbar({
      message: t(data.message),
      options: {
        key: new Date().getTime() + Math.random(),
        variant: data.variant,
        action: (key) => (
          <Button style={{ color: "#fff" }} onClick={() => closeSnackbar(key)}>
            {t("dismiss me")}
          </Button>
        ),
      },
    });
  };

  const { pathname } = useLocation();
  const history = useHistory();
  const defaultValues = {
    title: "",
    articleCategory: "",
    articleStatus: "",
    articles: [],
  };

  const { articleContent } = useSelector((state) => state.helpdesk);
  const [value, setValue] = useState(constants.LANGUAGES[0].value);

  const methods = useForm({
    resolver: yupResolver(generateValidationSchema(defaultValues.articles)),
    defaultValues,
  });

  const { user } = useSelector((state) => state.user);
  const { categories } = useSelector((state) => state.helpdesk);

  useEffect(() => {
    if (categories.length === 0 && open)
      dispatch(getAllCategories(user.websiteID));
  }, [user.websiteID, open]);

  useEffect(() => {
    if (open) {
      let newArticles = constants.LANGUAGES.reduce((acc, el) => {
        const foundItem = articleContent?.find(
          (item) => item?.language === el.value
        );

        if (
          foundItem &&
          clearHTMLTags(foundItem.articleContent)?.trim() !== ""
        ) {
          acc.push({ ...foundItem, title: "" });
        }

        return acc;
      }, []);

      if (pathname.includes("/edit")) {
        methods.reset({
          title: data?.article?.title || "",
          articleCategory: data?.article?.category?._id || "",
          articleStatus: data?.article?.status || "",
          articles: articleContent
            .filter((el) => el?.language !== "")
            ?.map((item) => {
              return {
                articleContent: item?.articleContent,
                language: item?.language,
                title:
                  data?.article?.translations?.find(
                    (el) => el?.language === item?.language
                  )?.title || "",
                id:
                  data?.article?.translations?.find(
                    (el) => el?.language === item?.language
                  )?._id || "",
              };
            }),
        });
      } else {
        methods.reset({
          ...defaultValues,
          articles: newArticles,
        });
      }

      if (newArticles.length > 0) {
        setValue(newArticles[0].language);
      }
    }
  }, [open, data]);

  const handleChangeTabs = (_, newValue) => {
    setValue(newValue);
  };

  const onSubmit = async (values) => {
    try {
      if (data?.article !== undefined) {
        dispatch(
          updateArticle({
            websiteID: user?.websiteID,
            id: data.article._id,
            data: {
              title: values.title,
              content:
                articleContent?.find((el) => el?.language === "")
                  ?.articleContent === ""
                  ? data?.article.content
                  : articleContent?.find((el) => el?.language === "")
                      ?.articleContent,
              category: values.articleCategory,
              status: values.articleStatus,
              translations: values.articles?.map((el) => {
                return {
                  title: el.title,
                  content: el.articleContent,
                  language: el.language,
                  id: el.id,
                };
              }),
            },
          })
        ).then((res) => {
          if (res.meta.requestStatus === "fulfilled") {
            showSnackbar({
              variant: "success",
              message: t("Article updated Successfully"),
            });
            handleClose(id);
            history.push(pathname.split("/")[2]);
          } else {
            showSnackbar({
              variant: "error",
              message: res?.error?.message,
            });
          }
        });
      } else {
        dispatch(
          createArticles({
            websiteID: user?.websiteID,
            data: {
              title: values.title,
              content: articleContent?.find((el) => el?.language === "")
                ?.articleContent,
              category:
                values.articleCategory === ""
                  ? undefined
                  : values.articleCategory,
              status: values.articleStatus,
              translations: values.articles?.map((el) => {
                return {
                  title: el.title,
                  content: el.articleContent,
                  language: el.language,
                };
              }),
            },
          })
        ).then((res) => {
          if (res.meta.requestStatus === "fulfilled") {
            reset();
            showSnackbar({
              variant: "success",
              message: t("Article successfully created"),
            });
            dispatch(emptyArticleContent());
            history.push(pathname.split("/")[2]);
            handleClose(id);
          } else {
            showSnackbar({
              variant: "error",
              message: res?.error?.message,
            });
          }
        });
      }
    } catch (err) {
      showSnackbar({
        variant: "error",
        message: t(err.message),
      });
    }
  };

  const categoriesOptions = categories.map((item) => {
    return {
      label: item.name,
      value: item._id,
    };
  });
  const { handleSubmit, reset, formState, register, control, watch } = methods;

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose(id);
        reset();
      }}
      className="info-modal add-article"
    >
      <LazyLoadImage
        src={AddArticleImg}
        className="assign-icon"
        alt="AddArticleImg"
      />
      <DialogTitle className="assign-operator-title">
        {" "}
        {t(
          (pathname.includes("/edit") ? "Update" : "Create a new") +
            " " +
            "Article"
        )}
      </DialogTitle>
      <DialogContentText className="assign-operator-text">
        {t(
          `In order to ${
            pathname.includes("/edit") ? "update" : "push"
          } your article to helpdesk you need to setup all this preferences`
        )}
      </DialogContentText>
      <DialogContent style={{ padding: " 20px 24px 5px 24px" }}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <div id="select-category">
            <Input
              label={t("Title")}
              placeholder={t("Title")}
              name={"title"}
              errors={formState.errors}
              register={register}
            />

            <Select
              data={categoriesOptions}
              name={"articleCategory"}
              label={t("Article Category")}
              id="add-article"
              errors={formState.errors}
              control={control}
              value={defaultValues.articleCategory}
            />

            <Select
              data={constants.ARTICLES_STATUS}
              name={"articleStatus"}
              label={t("Article Status")}
              id="article-status"
              errors={formState.errors}
              control={control}
              value={defaultValues.articleStatus}
              style={{ marginBottom: "15px" }}
            />

            {methods.watch("articles")?.length !== 0 && (
              <>
                <span className="translated-title">Translated Titles</span>
                <Box sx={{ mb: "9px" }}>
                  <Tabs value={value} onChange={handleChangeTabs}>
                    {constants.LANGUAGES.map(
                      (el, index) =>
                        articleContent?.find(
                          (item) => item?.language === el.value
                        )?.articleContent &&
                        clearHTMLTags(
                          articleContent?.find(
                            (item) => item?.language === el.value
                          )?.articleContent
                        )?.trim() !== "" && (
                          <Tab value={el.value} label={el.label} key={index} />
                        )
                    )}
                  </Tabs>
                </Box>

                <Controller
                  name={`articles[${methods
                    ?.watch("articles")
                    ?.findIndex((el) => el.language === value)}].title`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      value={`${
                        methods
                          ?.watch("articles")
                          ?.find((el) => el.language === value)?.title
                      }`}
                      placeholder={t(`Translated title`)}
                      fullWidth
                      onChange={(e) => {
                        field.onChange(e);
                        const articles = methods.getValues("articles");
                        articles[
                          articles.findIndex((el) => el.language === value)
                        ].title = e.target.value;
                        methods.setValue("articles", articles);
                      }}
                      helperText={
                        formState.errors?.articles?.[
                          methods
                            .watch("articles")
                            .findIndex((el) => el.language === value)
                        ]?.title?.message
                      }
                      error={
                        formState.errors?.articles?.[
                          methods
                            .watch("articles")
                            .findIndex((el) => el.language === value)
                        ]?.title?.message &&
                        Boolean(
                          formState.errors?.articles?.[
                            methods
                              .watch("articles")
                              .findIndex((el) => el.language === value)
                          ]?.title?.message
                        )
                      }
                      InputProps={{
                        className: "create-bot-question-TextField",
                      }}
                    />
                  )}
                />
              </>
            )}
          </div>
          <DialogActions
            className="assign-operator-btns"
            style={{ marginTop: "20px" }}
          >
            <Button
              className="assign-operator-cancel-btn"
              onClick={() => {
                if (data?.article !== undefined) {
                  handleClose(id);
                } else {
                  const title = methods.watch("title", false);
                  const category = methods.watch("articleCategory", false);
                  handleClose(id);
                  dispatch(
                    openModal("draft-article", {
                      title: title,
                      content: articleContent?.find((el) => el?.language === "")
                        ?.articleContent,
                      category: category,
                      status: "DRAFT",
                    })
                  );
                  reset();
                }
              }}
            >
              {t("Cancel")}
            </Button>
            <Button
              className="assign-operator-change-btn"
              color="primary"
              type="submit"
            >
              <LazyLoadImage src={sendIcon} style={{ marginRight: "5px" }} />
              {pathname.includes("/edit") ? t("Update") : t("Create")}
            </Button>
          </DialogActions>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

export default AddArticle;
